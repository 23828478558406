.heart {
  position: absolute;
  width: 20px;
  height: 20px;
  background: red;
  transform: rotate(-45deg);
  animation: float 2s ease-in-out forwards;
}

.heart::before,
.heart::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: red;
  border-radius: 50%;
}

.heart::before {
  top: -10px;
  left: 0;
}

.heart::after {
  left: 10px;
  top: 0;
}

@keyframes float {
  0% {
    opacity: 1;
    transform: translateY(0) rotate(-45deg);
  }
  100% {
    opacity: 0;
    transform: translateY(-100px) rotate(-45deg);
  }
}
